import { Typography, Grid} from "@mui/material"
export const Header = (props) => {
  return (
    <>
    <Typography>{props.data ? props.data.title : 'Loading'}</Typography>
    <Grid display={{ xs: "none", md:'block' }}>
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
         <div className='container'>
            <div className='row'>
              <div className='col-md-7  intro-text'>
                <div className="company-intro">
              <Grid  display={{ xs: "none", md:'block' }}>
                <h2 style={{color:'black',opacity:0.4, marginTop:-100}}>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h2> 
                <p style={{opacity:0.7, color:'black'}}>{props.data ? props.data.paragraph : 'Loading'}</p>
              </Grid>
              </div>
                {/* <a
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn More
                </a>{' '} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </Grid> 
    <Grid display={{ xs: "block", md:'none' }}>
    <header id='header' >
      <div className='mobileImg'>
        <div className='overlay'>
         <div className='container'>
            <div className='row'>
              <div className='col-md-12  intro-text'>              
              </div>
            </div>
          </div>
        </div>
      </div>
    <Grid container  justifyContent='center' mt={2} p={4}>
      <Grid item xs={12}>
        <h2 style={{color:'black',opacity:0.4}}>
          {props.data ? props.data.title : 'Loading'}
        </h2> 
          <p style={{opacity:0.7, color:'black'}}>{props.data ? props.data.paragraph : 'Loading'}</p>
      </Grid> 
    </Grid>
    </header> 
    </Grid>
    </>
  )
}
