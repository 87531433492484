import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";

export const Team = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1289 },
      items: 4,
      slidesToSlide: 3,
    },
    desktop_2: {
      breakpoint: { max: 1286, min: 1025 },
      items: 3,
      slidesToSlide: 2,
    },

    tablet: {
      breakpoint: { max: 1024, min: 540 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 539, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="custom-left-arrow"
        onClick={onClick}
        style={{
          position: "absolute",
          left: "-10px",
          top: "50%",
          transform: "translateY(-100%)",
          background: "transparent",
          border: "none",
          fontSize: "50px",
          cursor: "pointer",
          zIndex: 10,
          color: "white",
        }}
      >
        <CiCircleChevLeft />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="custom-right-arrow"
        onClick={onClick}
        style={{
          position: "absolute",
          right: "-10px",
          top: "50%",
          transform: "translateY(-100%)",
          border: "none",
          fontSize: "50px",
          cursor: "pointer",
          zIndex: 10,
          color: "white",
          background: "transparent",
        }}
      >
        <CiCircleChevRight />
      </button>
    );
  };

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
        </div>
      </div>
      <div id="row" className="Crousel-image-style">
        {props?.data ? (
          <>
            <Carousel
              responsive={responsive}
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              infinite={true}
            >
              {props?.data?.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  // className="col-md-3 col-sm-6 team team-list-item"
                >
                  <div className="thumbnail">
                    <img src={d?.img} alt="team-image" className="team-img" />
                    <div className="caption">
                      <h4>{d?.name}</h4>
                      <p>{d?.job}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </>
        ) : (
          "loading"
        )}
      </div>
    </div>
  );
};
