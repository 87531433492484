import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";

export const Testimonials = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="custom-left-arrow"
        onClick={onClick}
        style={{
          position: "absolute",
          left: "-10px",
          top: "50%",
          transform: "translateY(-100%)",
          background: "transparent",
          border: "none",
          fontSize: "50px",
          cursor: "pointer",
          zIndex: 10,
          color: "white",
        }}
      >
        <CiCircleChevLeft />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="custom-right-arrow"
        onClick={onClick}
        style={{
          position: "absolute",
          right: "-10px",
          top: "50%",
          transform: "translateY(-100%)",
          border: "none",
          fontSize: "50px",
          cursor: "pointer",
          zIndex: 10,
          color: "white",
          background: "transparent",
        }}
      >
        <CiCircleChevRight />
      </button>
    );
  };
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
      </div>
      {props.data ? (
        <div className="testimonial_container">
          <Carousel
            responsive={responsive}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            // autoPlay={props.deviceType !== "mobile"}
            infinite={true}
          >
            {/* <div className="testimonial-content-container"> */}
            {props.data.map((d, i) => (
              <div key={`${d.name}-${i}`}>
                <div className="testimonial">
                  <div className="testimonial-image">
                    <img src={d.img} alt="" />
                  </div>
                  <div className="testimonial-content">
                    <p>"{d.text}"</p>
                    <div className="testimonial-meta"> - {d.name} </div>
                  </div>
                </div>
              </div>
            ))}
            {/* </div> */}
          </Carousel>
        </div>
      ) : (
        "loading"
      )}
    </div>
  );
};
